.pdf-page {
  .cap-table {
    .cap-cell {
      width: 241px;
    }
  }
  .title_description {
    position: relative;
    text-align: center;
    font-size: 10px;
  }

  .four_points_color {
    background-color: rgb(130, 202, 219);
  }

  .ceiling_points {
    display: flex;
    margin: 6px 0 6px 5%;
    font-size: 12px;
    align-items: center;
    gap: 6px;
  }

  .cap_category {
    display: flex;
    gap: 10px;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    span {
      width: auto !important;
      max-width: calc(100% - 33px);
      word-wrap: break-word;
    }
  }
}

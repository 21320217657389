@use '../../assets/styles/variables';

section.shrink {
  height: 35px;
  label {
    width: 95%;
  }
}

.section {
  margin: auto;
  .contain-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
  }
  .title_section {
    font-family: 'Rubik-Medium';
    color: variables.$grey;
    font-size: 30px;
    letter-spacing: 0.2rem;
    padding: 20px 40px 40px;
    margin: 0;
    @media (max-width: 1600px) {
      font-size: 24px;
      padding: 20px 40px 30px;
    }
  }
  .title_section_recapitulatif {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Rubik-Medium';
    color: variables.$grey;
    font-size: 30px;
    letter-spacing: 0.2rem;
    padding: 10px 40px 10px;
    margin: 0;
    z-index: 1;
    position: sticky;
    background-color: whitesmoke;
    transition: all 0.5s ease-out;
    @media (max-width: 1600px) {
      font-size: 24px;
      padding: 10px 40px 10px;
    }
  }
  .add_button_container {
    text-align: right;
    padding: 20px 80px;
  }
  .select_all_button_container {
    text-align: left;
    padding: 20px 80px;
  }

  .group_button {
    margin-left: 15px;
  }

  .green_button {
    border: 1px solid #277134;
    margin-left: 15px;
  }
  .main_container {
    max-height: 100vh;
    overflow-y: hidden;

    .map_button_container {
      padding: 20px 0;
      margin-left: 65px;
    }

    & .parcels_button_container {
      display: flex;
      padding: 20px 80px;
      gap: 10px;
    }
  }
}

.section .override {
  margin-bottom: 30px;
  .add_button_container {
    padding: 0 80px !important;
  }

  .group_button {
    margin-left: 15px;
  }
}

.main_container_without_button {
  height: calc(100vh - 150px);
  overflow-y: auto;
}

.ExploitationContainer {
  padding-top: 20px;
}

.Exploitation {
  padding: 0px 70px;
}

.Content {
  overflow-y: auto;
  padding-top: 5px;
}

.Content_simulation {
  padding-top: 5px;
}

.notes {
  margin-top: 40px;
  margin-left: 50px;
}

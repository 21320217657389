@use "../../../assets/styles/variables";

.page {

  .table-scroll{
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 57vh;
  }
  @media (min-width: 2560px) {
    .table-scroll{
      max-height: 70vh;
    }
  }
  @media (min-width: 3000px) {
    .table-scroll{
      max-height: 75vh;
    }
  }
  // #### RESET ####
  table,
  thead,
  tbody,
  tfoot,
  tr,
  th,
  td {
    //  display: block;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    border-collapse: inherit;
    border-spacing: 0;
    border-color: inherit;
    vertical-align: inherit;
    text-align: left;
    font-weight: inherit;
    -webkit-border-horizontal-spacing: 0;
    -webkit-border-vertical-spacing: 0;
  }
  
  // #### RESET ####
  
  table {
    background: variables.$white;
    width: calc(100% - 160px);
    margin: 0 80px;
    // -ms-overflow-style: none;  /* IE and Edge */
    // scrollbar-width: none;  /* Firefox */
    // &::-webkit-scrollbar {
    //   display: none;
    // }
    &.PAChelp tr {
      height: 30px !important;
    }
    thead {
      color: variables.$white;
      background: variables.$secondary;
      font-family: "Rubik-Bold";
  
      position: sticky;
      z-index: 1;
  
      tr {
        height: 60px;
        vertical-align: middle;
        th {
          background: variables.$secondary;
          position: sticky;
          top: 0;
          &:first-child {
            border-radius: 5px 0 0 5px;
          }
          &:last-child {
            border-radius: 0 5px 5px 0;
          }
          flex: 1;
          padding: 0 2%;
        }
      }
    }
    tbody {
      font-family: "Rubik-Medium";
      color: variables.$grey;
      tr {
        height: 100px;
        vertical-align: middle;
        box-sizing: border-box;
        td {
          border-bottom: 1px solid variables.$greylight;
          padding: 0 2%;
          span.tableSubElement {
            display: block;
          }
        }
        &:last-child td {
          border-bottom: none;
        }
      }
    }
  }
}
@use "../../../assets/styles/variables";

body {
  margin: 0;
}

.Home {
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
  position: relative;
  border: solid 10px #70b68433;
  &::after {
    content: "ASSOLIA";
    transform: rotate(-90deg) translate(0, -100%);
    transform-origin: 100% 0;
    padding: 0;
    margin: 0;
    font-family: "Rubik-Bold";
    font-size: 121px;
    position: absolute;
    top: 30px;
    right: 5px;
    color: #70b68433;
  }
  .home_main_logo {
    margin-left: 57.5px;
    width: 303px;
  }
  test  .home_logo {
    margin-top: 30px;
    margin-left: 50px;
  }
  .home_content {
    width: 100%;
    text-align: center;
    img {
      height: 40vh;
    }
    h1 {
      font-size: 35px;
      width: 40%;
      padding: 30px 0;
      margin: auto;
    }
  }
}

.pdf-page {
  .title_h6 {
    justify-self: flex-start !important;
  }

  .table {
    justify-self: flex-start !important;
  }

  .constraint_word_spacing {
    word-spacing: 3px;
  }

  .constraints_list_wrapper {
    height: 100%;
    & .constraints_table {
      height: 100%;
    }
  }
}

@use '../../../../assets/styles/variables';

.section {
  & .title_section_forecast {
    color: variables.$green;
    display: inline-block;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      width: 225px;
      height: 5px;
      background: variables.$yellow;
      top: 53px;
      bottom: 0;
      right: 0;
      left: 107px;
    }
  }
  & .filter_container {
    display: flex;
    align-items: center;
    margin: auto;
    width: 90%;
    margin-bottom: 25px;
    background-color: variables.$green;
    border-radius: 10px;
    .button_filters {
      padding: 10px;
      display: flex;
      border: 1px solid variables.$green;
      border-radius: 10px;
      cursor: pointer;
      width: 170px;
      justify-content: space-between;
      margin: 8px;
      color: variables.$green;
      background-color: white;
      align-items: center;
      position: relative;
      max-height: 28px;
      white-space: nowrap;
      span {
        color: variables.$green;
        font-family: 'Rubik-Medium';
      }
    }
    & button {
      padding: 10px 12px;
      border-radius: 10px;
      margin: 8px;
      background-color: variables.$white;
      color: variables.$green;
      font-family: 'Rubik-Medium';
      max-height: 40px;
      white-space: nowrap;
    }
    & .parcel-hidden {
      color: #fff;
      & span {
        text-align: center;
      }
    }
  }
  & .parcel_datagrid_container,
  .parcel-forecast-datagrid {
    width: 90%;
    margin: auto;
    .cellBox {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: 8px;
      &.cellHasBeenUpdated {
        border: 1px solid #225c2c;
      }
      &.highlight {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
    & .forecast_select {
      height: auto;
      min-height: 1.4375em;
      overflow: hidden;
      white-space: nowrap;
      overflow: hidden;
    }
    & .parcel_children {
      justify-content: end !important;
      position: relative;
      margin-left: -10px !important;
      margin-right: 10px;
    }

    & .parcel_children_forecast {
      margin-left: 50px !important;
    }

    & .parcel_children_fixed {
      margin-left: unset !important;
    }

    & .first-children {
      justify-content: start !important;
      position: relative;
      padding-left: 6.5% !important;
    }

    & .first-children::before {
      content: '';
      border-bottom: 1px solid #000;
      border-left: 1px solid #000;
      bottom: 0;
      height: 50%;
      left: -5px;
      position: relative;
      top: -8px;
      width: 10px;
    }
    .MuiDataGrid-cell {
      white-space: pre-wrap !important;
      text-align: center !important;
    }
  }
  .contain-header-forecast {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 95%;
    position: sticky;
    top: 125px;
    z-index: 15;
    background: variables.$white;
  }
  .boutons_section_forecast {
    display: flex;
    gap: 10px;
    & .button_container_forecast {
      button {
        background-color: variables.$green;
        font-family: 'Rubik-Medium';
        text-transform: uppercase;
        letter-spacing: 0.5px;
        padding: 12px 22px;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
        transition: 0.1s;
        font-size: 15px;
        height: 50px;
        &:hover {
          background: #e69636;
        }
      }
    }
  }
}

.close-filter {
  position: absolute;
  top: 1px;
  right: 10px;
  bottom: 0;
  color: #e69636;
  cursor: pointer;
  font-size: 18px !important;
}

.MuiDataGrid-row.Mui-even {
  background-color: variables.$white !important;
  &:hover {
    background-color: variables.$white !important;
  }
}
.MuiDataGrid-row.Mui-odd {
  background-color: #f0f0f0 !important;
  &:hover {
    background-color: #f0f0f0 !important;
  }
}

.header-material .MuiDataGrid-columnHeaderTitleContainerContent {
  display: flex;
  flex-direction: column;
  & p {
    line-height: 15px;
    text-align: center;
    font-family: 'Rubik-Medium';
    span {
      font-weight: 400 !important;
      display: flex;
      justify-content: center;
    }
  }
}
.parcel_datagrid_container,
.parcel-forecast-datagrid {
  .cellBox {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 2px;
    white-space: normal;
    text-align: center;
    &.cellHasBeenUpdated {
      border: 1px solid #225c2c;
    }
    &.highlight {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
}

.forecast_checkbox {
  &.cellHasBeenUpdated {
    border: 1px solid #225c2c;
    padding: 5px 10px 0px 10px;
  }
  &__disabled {
    cursor: not-allowed;
  }
}
.forecast_select {
  height: auto;
  min-height: 1.4375em;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
}

.parcel-forecast-datagrid {
  .parcel_children {
    justify-content: end !important;
    position: relative;
    margin-left: -10px !important;
    margin-right: 10px;
  }

  .first-children {
    justify-content: start !important;
    position: relative;
    padding-left: 8% !important;
  }

  .first-children::before {
    content: '';
    border-bottom: 1px solid #000;
    border-left: 1px solid #000;
    bottom: 0;
    height: 50%;
    left: -5px;
    position: relative;
    top: -15px;
    width: 10px;
  }

  .MuiDataGrid-cell {
    white-space: pre-wrap !important;
    text-align: center !important;
  }

  .MuiDataGrid-ParcelChild--row {
    background-color: #f0f0f0;
    &:hover {
      background-color: #d6d6d6 !important;
    }
  }
}

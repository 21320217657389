.common-list-data-grid {
  width: 95%;
  margin: auto;
  & .MuiDataGrid-overlayWrapper {
    height: 25vh;
  }
  & .noRowOverlay {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    gap: 20px;
    .noRowOverlay-icon {
      width: 10px;
      height: 10px;
    }
  }
  .MuiDataGrid-row.Mui-even {
    background-color: white !important;
    &:hover {
      background-color: #d4d4d4 !important;
    }
  }
  .MuiDataGrid-row.Mui-odd {
    background-color: #f0f0f0 !important;
    &:hover {
      background-color: #d4d4d4 !important;
    }
  }
}

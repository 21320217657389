@use "../../assets/styles/variables";

.grid {
  width: 80%;
  margin: auto;
  text-align: center;
  .title_grid {
    font-family: "Rubik-Medium";
    color: variables.$grey;
    font-size: 30px;
    letter-spacing: 0.2rem;
    padding: 20px 40px 40px 0;
    text-align: left;
  }
}

.containerGrid {
  display: flex;
  flex-direction: row;
  text-align: center;
  flex-wrap: wrap;
  .itemGrid {
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;
    margin: 10px;
    background-color: variables.$secondary;
    color: variables.$white;
    font-family: "Rubik-Bold";
    font-size: 20px;
    letter-spacing: 2px;
    overflow: hidden;
    padding: 20px;
    box-sizing: border-box;
    &:hover {
      background-image: url("../images/itemBackground.png");
      background-size: contain;
      img {
        display: none;
      }
    }
    &.active {
      text-decoration: underline;
      background-image: url("../images/itemBackground.png");
    }
    p {
      margin: 0;
    }
  }
}

@use '../../assets/styles/variables';

.dialogcontent-select-parcel{
  min-height: 50px;
}

.group-parcel-list{
  overflow-y: hidden !important;

  .parcel-list {
    
    &__wrapper {
      border: 2px solid #e69636;
      max-height: 100%;
      width: 100%;
      margin: 20px 0 20px 20px;
      overflow-y: auto;
    }

    &__item {
      margin: 0 !important;
      span {
        overflow: hidden;
      }
    }
  }
}


.button_delete {
  background-color: variables.$red;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &:hover {
    cursor: pointer;
    scale: 1.15;
  }
}

#multiSelectParcel,
#selectMainParcel {
  p {
    position: relative;
    bottom: -10px;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

// ParcelTableRowGroup scss
.child_row {
  background-color: #ecf0f1;
  .child_name {
    padding-left: 20px;
    position: relative;

    .arrow {
      font-size: 25px;
    }

    .separator {
      position: absolute;
      height: 50%;
      top: 0;
      width: 10px;
      left: 5px;
      bottom: 0px;
      border-left: solid 1px black;
      border-bottom: solid 1px black;
    }
  }
}

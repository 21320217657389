@use "../../../assets/styles/variables";

.section.simulation {
  .content {
    display: flex;
    flex-direction: row;
    .simulation_step_container {
      padding: 60px 40px 0 40px;
      .simulation_step {
        box-sizing: border-box;
        letter-spacing: 1.8;
        height: 80px;
        color: variables.$greylight;
        width: 200px;
        font-family: "Rubik-SemiBold";
        font-size: 16px;
        display: flex;
        margin: 10px 0;
        align-items: center;
        padding-left: 5px;
        cursor: pointer;
        &.active {
          padding-left: 0px;
          border-left: 5px solid variables.$secondary;
          color: variables.$secondary;
          background: variables.$greylight;
        }
        &.passed {
          color: variables.$secondary;
        }
        &:hover {
          background: variables.$greylight;
          color: variables.$secondary;
        }
        p {
          padding: 0 20px;
          text-align: left;
        }
      }
    }
    .step {
      width: 100%;
      padding: 0 20px 0 0;
      display: inline-block;
      text-align: right;
      p {
        text-align: left;
      }
      .minMax {
        padding-left: 60px;
        text-align: left;
        width: 100%;
        color: variables.$grey;
        p,
        input {
          text-align: center;
          display: inline-block;
          width: 60px;
          align-content: center;
          margin-top: auto;
          margin: 0;
        }
        input {
          height: 20px;
          color: variables.$grey;
          border-color: variables.$grey;
          &::-webkit-input-placeholder {
            color: variables.$grey;
          }
          &::-moz-placeholder {
            color: variables.$grey;
          }
        }
        p {
          padding: 0 10px;
          width: auto;
        }
      }
      .input_field {
        color: variables.$grey;
        text-align: left;
        input {
          text-align: center;
          margin-left: 20px;
          width: 60px;
          display: inline-block;
          color: variables.$grey;
          border-color: variables.$grey;
          &::-webkit-input-placeholder {
            color: variables.$grey;
          }
          &::-moz-placeholder {
            color: variables.$grey;
          }
        }
        p {
          width: auto;
          display: inline-block;
        }
      }
      .recap {
        position: relative;
        border: 10px solid #70b68444;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        padding: 0;
        margin-top: 50px;
        margin-bottom: 40px;
        &::before {
          content: "RÉCAPITULATIF";
          position: absolute;
          top: -56px;
          left: 40px;
          font-size: 50px;
          font-family: "Rubik-Bold";
          color: #70b68444;
        }
        .column {
          flex: 1;
          padding: 50px 20px;
          text-align: left;
          &.borderRight {
            border-right: 1px solid #70b68444;
          }
          h2 {
            font-size: 18px;
            display: inline-block;
            letter-spacing: 2px;
            font-family: "Rubik-Medium";
            padding: 4px 10px;
            background: variables.$secondary;
            color: variables.$white;
          }
          p {
            font-family: "Rubik-Medium";
            font-size: 15px;
            color: variables.$grey;
            padding: 5px 10px;
          }
        }
      }
      [type="submit"] {
        margin-bottom: 20px;
      }
      .card {
        width: 100%;
        border-radius: 10px;
        box-shadow: 0px 3px 20px #0000000f;
        .headerCard {
          min-width: 645px;
          background: variables.$secondary;
          color: variables.$white;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          p {
            margin: 0;
            padding: 25px 0;
            text-align: center;
            font-size: 18px;
            font-family: "Rubik-Medium";
            letter-spacing: 2px;
          }
        }
        .containCard {
          text-align: center;
          margin: auto;
          padding: 5% 10%;
          &__restriction{
            text-align: center;
            margin: 20px auto 0;
            width: 95%;
          }
          .listItem {
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10;
            .minMax {
              padding-left: 0;
              text-align: right;
              display: flex;
              align-items: flex-end;
              justify-content: flex-end;
              span {
                margin-right: 5px;
                p {
                  padding: 0;
                  margin: 0;
                }
                input {
                  height: 15px;
                }
              }
              input {
                padding: 0;
                margin: 0 5px 0 5px;
                margin-right: 5px;
              }
            }
            .checkbox_with_label {
              width: 100%;
            }
          }
        }
        .parent {
          color: variables.$grey;
          font-family: "Rubik-Medium";
        }
        .disabled-coverProposal {
          & .checkbox_container, p {
            opacity: 0.7;
            cursor: not-allowed;
          }
        }
        .requirements {
          font-family: "Rubik-Medium";
          color: variables.$yellow;
        }
      }
    }
  }
  hr {
    margin-top: 40px;
    border-top: 1px solid variables.$primary;
  }
}

.step {
  .group_button:first-child {
    margin-left: 0;
  }

  .map {
    &_button_container {
      display: flex;
      padding: 0;
    }

    &_section {
      padding-top: 2%;
    }
  }
}

.add_restriction_button {
  margin-top: 50px;
  margin-bottom: 30px;
  background-color: variables.$green;
}

.error_message {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  .error_obligation {
    color: #eb4d4b;
    font-weight: 700;
    text-align: center !important;
  }
}

.obligation_and_restriction {
  display: flex;
  flex-direction: column;
  .row_obligation {
    padding: 30px 15px;
    border: 1px solid variables.$green;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    &:first-of-type{
      margin-top: 0;
    }

    .bloc_obligation {
      display: flex;
      flex-direction: column;
      align-items:flex-start;
      width: calc((90%/3) -  63px);

      &:nth-of-type(4){
        width: fit-content;
      }
      &:nth-of-type(5){
        min-width: 63px;
        width: 63px;
        align-items: center;
        justify-content: flex-start;
      }
      
      .label_bloc_obligation{
        font-size: larger;
        padding-bottom: 8px;
        color: variables.$green;
      }

      .multi_badge {
        margin-top: 5px;
        display: flex;
        justify-content: left;
        flex-flow: row wrap;
        column-gap: 10px;
        row-gap: 3px;
        
        .badge_years{
          word-break:normal !important;
        }
        .badge {
          font-size:13px;
          word-break: break-word;
          padding: 4px 2px 4px 8px;
          border-radius: 99px;
          margin: 1px;
          color: variables.$green;
          border: 1px solid variables.$yellow;
          display: flex;
          align-items: center;
          text-transform:lowercase;
          gap: 8px;

          .delete {
            color: #eb4d4b;
            font-weight: 800;
            margin-left: 2px;
            display: flex;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      .delete_obligation {
        margin-top: 42px;
        &:hover {
          cursor: pointer;
          scale: (1.2); 
        }
      }
    }
  }
}

.missing_data {
  color: #eb4d4b;
}

.error {
  background-color: #eb4d4b;
  width: 70% !important;
  color: white;
  margin-left: 280px !important;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px !important;
}

.modal {
  .content {
    min-width: 450px;
  }

  .button_container {
    text-align: center;
  }

  h2 {
    text-align: center;
  }
  .parcel_info {
    min-width: 200px;
  }
  .update_modal {
    color: white;

    .content p {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .obligation_restriction_details {
      width: 100%;
      .interdiction_obligation {
        display: flex;
        justify-content: space-between;

        gap: 20px;

        .color {
          border: 1px solid black;
          width: 30px;
          height: 30px;
          border-radius: 5px;
          display: block;
          float: left;
          margin-right: 10px;
          margin-top: -8px;
        }
      }
    }
    .restrictions_input {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 8px;

      list-style-type: none;
      margin: 25px 0 0 0;
      padding: 0;

      li {
        float: left;
        margin: 0 5px 0 0;
        width: 130px;
        height: 60px;
        position: relative;
      }

      label,
      input {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        cursor: pointer;
      }

      input[type="radio"] {
        opacity: 0.01;
        z-index: 100;
      }

      #input_obligation:checked + label,
      .Checked + label {
        background: rgba(0, 255, 13, 1);
      }

      #input_interdiction:checked + label,
      .Checked + label {
        background: rgba(255, 0, 0, 1);
      }

      #input_neutre:checked + label,
      .Checked + label {
        background: rgba(167, 167, 167, 1);
      }

      #input_obligation:hover + label,
      .Checked + label {
        background: rgba(0, 255, 13, 0.8);
      }

      #input_interdiction:hover + label,
      .Checked + label {
        background: rgba(255, 0, 0, 0.8);
      }

      #input_neutre:hover + label,
      .Checked + label {
        background: rgba(167, 167, 167, 0.8);
      }

      label {
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 5px;
        cursor: pointer;
        z-index: 90;
      }

      label:hover {
        background: #ddd;
      }

      &_container {
        padding-bottom: 50px;
        display: flex;
        justify-content: space-evenly;
      }
    }
  }
}

#results-modal {
  .MuiPaper-root {
    height: auto;
  }
  & .MuiDialogTitle-root {
    display: flex;
    align-items: center;
    & .MuiSvgIcon-root {
      width: 22px;
      height: 22px;
      padding-left: 5px;
    }
  }
  & .result-info-stack {
    align-items: center;
    margin-bottom: 10px;
    justify-content: center;
    width: 100%;
    span {
      display: flex;
      align-items: center;
    }

    & .result-info-chip {
      color: white;
      border-color: variables.$yellow;
      span {
        color: variables.$yellow;
      }
    }
  }
  .culture-infos-icon {
    height: 18px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    .dropletLogo {
      height: 16px;
      width: 16px;
      padding-right: 5px;
    }
    span {
      align-self: center;
    }
  }
  .MuiInputBase-root:last-child {
    margin-bottom: 10px;
  }
  .irrigable-center-splitted {
    max-width: 46%;
    & .MuiBox-root {
      display: flex;
      justify-content: flex-start !important;
      min-width: 100%;
    }
  }
  #irrigable-center {
    width: 100% !important;
    .Mui-disabled  {
      pointer-events: unset !important;
      cursor: not-allowed !important;
      .MuiSvgIcon-root {
        color: #d2d2d2 !important;
      }
    }
  }
  .wrapper-cultures-select {
    min-width: 90%;
    margin-bottom: -10px;
    > .MuiBox-root {
      min-width: 100%;
    }
    &__splitted {
      min-width: 45% !important;
      margin-top: -10px;
      & .MuiBox-root {
        min-width: 100%;
         span {
          color: variables.$secondary;
          font-size: 16px;
        }
      }
    }
    &__infos, &__year, &__title, &__surface {
      min-width: 100%;
      margin: 0 !important;
      color: variables.$secondary;
      > div {
        padding: 5px;
      }
    }
  }
}

.labelNeutre { background: rgba(167, 167, 167, 0.8); }
.labelObligation { background: rgba(0, 255, 13, 0.8); }
.labelInterdiction { background: rgba(255, 0, 0, 0.8); }
@use '../../../assets/styles/variables';

.cultures-map-tab-container {
  width: 100%;

  & .years-map-tab-container {
    & button {
      background-color: transparent;
      border-radius: 0;
      padding-left: 0;
      padding-right: 10px;
      flex: 50%;
    }
    & .button_light {
      opacity: 0.3;
    }
  }

  .table-container {
    overflow: auto;
    max-height: 80vh;
    &.sticky-table {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: unset;
      height: 425px;
    }
  }

  .table-production {
    tr {
      height: 25px;
    }
    td {
      font-size: 13px;
      padding: 2px !important;
    }
    td:first-child {
      width: 20px !important;
    }
    .column_subName {
      height: 20px;
    }
    .column_subName > * {
      width: 60px !important;
      font-size: 13px;
    }
    .culture-color {
      width: 15px !important;
      height: 15px !important;
    }
    table {
      .tr {
        height: 25px;
      }
    }
    .cultures-map-tab-surface p {
      font-size: 12px;
    }
    .column_name,
    .last_column_name {
      text-align: center;
    }
  }

  .cultures-map-tab-surface {
    border-top: 1px solid variables.$grey;
    padding-left: clamp(5px, 1.5%, 1.5%);
    padding-right: clamp(5px, 5px, 1.5%);
    &.sticky-table {
      position: sticky;
      bottom: 0px;
      height: 38px;
      background-color: white;
    }
    p {
      color: variables.$grey;
      display: flex;
      justify-content: space-between;
    }
  }
  .dynamic-table {
    cursor: pointer;
    tbody:hover .tr {
      opacity: 0.3 !important;
      transition: all 0.3s;
    }
    tbody .tr:hover {
      opacity: 1 !important;
      transition: all 0.3s;
    }
  }
  .cultures-map-tab-table {
    cursor: default;
    border-collapse: collapse;
    border-style: hidden;
    margin: 0 auto;

    tr {
      height: 25px;
    }

    td {
      border: 1px solid grey;
      padding: 5px;
    }

    td:first-child {
      width: 40px;
    }
    .column_name,
    .last_column_name {
      font-weight: bold;
    }
    td:nth-child(2),
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5),
    .column_name:nth-child(3),
    .column_name:nth-child(4) {
      text-align: center;
    }
    .last_column_name {
      text-align: center;
      padding-right: 15px;
    }
    .column_subName {
      height: 25px;
    }
    .column_subName > * {
      text-align: center;
      width: 80px;
      padding-bottom: 5px;
    }
    .culture-color {
      border: 1px solid black;
      width: 30px;
      height: 30px;
      border-radius: 5px;
    }
    .table-synthesis-sticky {
      top: 0;
    }
  }

  table {
    margin: 0 !important;
    width: 100%;
    border-collapse: collapse;
    .tr {
      height: 50px;
    }
    thead {
      th {
        &:first-child {
          border-radius: 0 !important;
        }
        &:last-child {
          border-radius: 0 !important;
        }
      }
    }
  }
  thead.sticky-table {
    position: sticky;
    top: 0;
    background: white;
  }
}

.MuiInputBase-root {
  background: white;
}

#cultureList {
  min-width: 160px;
}

#popup-content {
  // this custom toggle switch can be moved to a generic component and reused in the future if needed

  .parcel_title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
  }

  .popup-content__wrapper {
    display: flex;
    gap: 20px;
  }

  .popup-content__left {
    min-width: 200px;
    &__label {
      min-width: 90px;
      margin-right: 10px;
    }
    &__parcel {
      min-width: 120px;
    }
  }

  .popup-content__right {
    min-width: 200px;
    &__title {
      margin: 0 !important;
      padding-top: 15px !important;
    }
    &__label {
      max-width: 70px;
    }
    &__culture {
      min-width: 120px;
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
.years-map-tab {
  display: flex;
  justify-content: center;
  width: 100%;

  &-container {
    height: 80px;
    border-bottom: 1px solid white;
    background: variables.$secondary;
    color: variables.$white;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.parcel-info-modal {
  p {
    display: flex;
    justify-content: space-evenly;
    span {
      width: 100%;
      font-size: 11pt;
    }
  }
}

.close {
  position: absolute;
  display: inline-block;
  top: 20px;
  right: 20px;
  svg {
    cursor: pointer;
    padding: 5px;
  }
}

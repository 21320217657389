@use '../../../assets/styles/variables';

.title_rotate_effects {
  margin-bottom: -25px !important;
}
.rotate_effects_container {
  width: 90%;
  margin: auto;
  .MuiDataGrid-row.Mui-selected {
    background-color: rgb(0, 0, 0);
  }

  .viewModeCell {
    padding: 1px 1px !important;
    border: 1px solid;

    .cellBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      &.cellHasBeenUpdated {
        border: 1px solid #225c2c;
      }
      &.highlight {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
    .noColumns {
      position: absolute;
      top: 90px;
      z-index: 10;
    }
  }
  .viewModeCell-noColumns {
    border-color: rgba(224, 224, 224, 0) !important;
  }

  & .cropToPlant-header {
    background-color: #225c2c !important;
    div {
      line-height: 24px !important;
      p {
        display: flex;
        align-items: center;
        span {
          white-space: pre-wrap;
        }
      }
    }
  }
  & .cropEffect-header {
    background-color: variables.$secondary !important;
    display: flex;
    justify-content: center;
    & div:first-child {
      transform: rotate(170deg);
      white-space: pre-wrap;
      line-height: 1;
      overflow: unset;
      text-align: center;
      font-size: 14px;
      max-width: 120px;
      padding: 5px;
    }
  }
  & .cropToPlant-cell {
    background-color: variables.$secondary !important;
    color: white;
    white-space: pre-wrap !important;
    .crop-to-plant-box {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
    }
  }

  & .contain_tab_rotation {
    display: flex;
    align-items: center;
    margin-top: -20px;
    > div {
      margin: 10px;
      padding: 10px;
      cursor: pointer;
    }
    & .active {
      border-bottom: 3px solid variables.$primary;
    }
    & .disabled {
      cursor: not-allowed;
    }
  }

  .brush-edit-cell {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
  }

  .edit-cell {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    .edit-cell-textfield {
      input {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: center;
      }
    }
  }

  @media (max-width: 1450px) {
    .contain_brush_effect_fullscreen {
      margin-bottom: 50px !important;
    }
  }

  & .contain_brush_effect {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 64px;
    > div {
      display: flex;
      align-items: center;
      & .contain_brush {
        margin-left: 40px;
      }
    }

    .brush_logo {
      width: 30px;
      height: 30px;
    }
    & .list_effect {
      display: flex;
      span.colorSpan {
        &:hover {
          transform: scale(1.2);
        }
        padding: 8px 16px;
        margin: 0 8px;
      }
    }
    & .filter_container_rotate_effects {
      display: flex;
      align-items: center;
      width: 200px;
      .formControl {
        .input-label {
          top: -7px;
        }
        .MuiBadge-badge {
          right: 0;
          bottom: 50px;
          align-self: center;
        }
      }
      .button_filters_effects {
        width: 100%;
        padding: 10px;
        display: flex;
        border: 2px solid variables.$green;
        cursor: pointer;
        margin: 8px;
        color: variables.$green;
        background-color: white;
        align-items: center;
        position: relative;
        span {
          color: variables.$green;
          font-family: 'Rubik-Medium';
          padding-right: 5px;
          white-space: nowrap;
        }
        .settings-icon {
          color: variables.$yellow;
        }
      }
      & button {
        padding: 10px 12px;
        border-radius: 10px;
        margin: 8px;
        background-color: variables.$white;
        color: variables.$green;
        font-family: 'Rubik-Medium';
        max-height: 40px;
      }
    }
    @media (max-width: 1600px) {
      .not-fullscreen {
        min-width: 50px;
      }
    }
    @media (max-width: 1500px) {
      .not-fullscreen {
        min-width: 100px;
      }
    }
    @media (max-width: 1300px) {
      .not-fullscreen {
        min-width: 200px;
      }
    }
  }
  & .contain_switch_effect {
    display: flex;
    justify-content: flex-end;
    margin-right: 40px;
    position: relative;
    & .MuiSvgIcon-root {
      width: 34px;
      height: 34px;
      color: variables.$primary;
      cursor: pointer;
    }
  }
  & .fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    padding: 20px;
    background-color: white;
    & .MuiDataGrid-root {
      margin-right: 30px;
      & .MuiDataGrid-virtualScroller {
        max-height: 80vh;
      }
    }
    @media (max-width: 1600px) {
      .fullscreen-div {
        min-width: 100px;
      }
    }
    @media (max-width: 1500px) {
      .fullscreen-div {
        min-width: 200px;
      }
    }
    .contain_brush {
      margin-left: 5px !important;
      margin-right: 20px;
    }
    @media (max-width: 1450px) {
      .contain_brush_fullscreen {
        white-space: nowrap;
        position: absolute;
        top: 45px;
        right: -20px;
      }
    }
  }

  .noRowOverlay {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    gap: 20px;
    .noRowOverlay-icon {
      width: 10px;
      height: 10px;
    }
  }
}

.modeBrush {
  cursor: url('/assets/images/brush.svg') 15 5, auto;
}

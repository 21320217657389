@use "../../assets/styles/variables";

.searchbar {
  border: none;
  border-bottom: 1px solid;
  border-color: variables.$grey;
  padding: 5px 0;
  padding: 12px 20px 12px 40px; /* Add some padding */
  margin-bottom: 12px; /* Add some space below the input */
  outline-width: 0;
  // background-image: url('/css/searchicon.png'); /* Add a search icon to input */
  background-image: url("../../assets/images/searchicon.png") !important;
  background-position: 5px 10px; /* Position the search icon */
  background-repeat: no-repeat; /* Do not repeat the icon image */
  background-size: 20px;

  width: 300px;
  font-family: "Rubik";
  &::-webkit-input-placeholder,
  &::-moz-placeholder {
    color: variables.$grey;
  }
}

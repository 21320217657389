@use '../../../../assets/styles/variables';

.nitrogen-key-figures-container {
  & .MuiDataGrid-cell {
    white-space: unset !important;
  }
  &__title {
    display: flex;
    align-items: center;
  }
  &__opened {
    opacity: 1;
    max-height: 5000px;
    overflow: hidden;
    transition: all 0.1s ease-in-out;
  }
  &__closed {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.1s ease-in-out;
  }

  & .previous-campain {
    background-color: #f0f0f0;
  }

  & .campaign-cell {
    display: flex;
    align-items: center;
    &__wrapper {
      display: flex;
      align-items: flex-end;
      line-height: 1.43 !important;
    }
    &__main {
      line-height: 1.43 !important;
      margin: 16px 0 !important;
      max-height: inherit !important;
      display: -webkit-box !important;
      -webkit-line-clamp: 2 !important;
      line-clamp: 2 !important;
      -webkit-box-orient: vertical !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
    }
    &__group {
      display: -webkit-box !important;
      -webkit-box-orient: vertical !important;
      -webkit-line-clamp: 2 !important;
      line-clamp: 2 !important;
      overflow: hidden !important;
      margin-bottom: 0;
      margin-top: 0;
      flex: 1;
    }
    &__child {
      text-align: unset;
    }
    &__unit {
      text-align: center;
      font-style: italic;
      font-size: 14px;
      color: #707070;
      padding-bottom: 1.5px;
    }
    &__first-children {
      justify-content: start !important;
      padding-left: 7% !important;
    }
    &__first-children::before {
      content: '';
      border-bottom: 1px solid #000;
      border-left: 1px solid #000;
      bottom: 0;
      height: 50%;
      left: -5px;
      position: relative;
      top: -10px;
      width: 10px;
    }
  }
  & .nitrogen-children {
    display: flex;
    justify-content: flex-end !important;
    padding-right: 50px !important;
  }

  &__legend {
    font-size: 14px;
    color: variables.$grey;
    font-style: italic;
    margin-bottom: 0;
    margin-left: 80px;
  }
  &__asterisk {
    font-weight: bold;
    color: variables.$yellow;
  }
}

@use "../../assets/styles/variables";

.modal {
  background: variables.$secondary;
  position: absolute;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 201;
  .close {
    position: absolute;
    display: inline-block;
    top: 20px;
    right: 20px;
    svg {
      cursor: pointer;
      padding: 5px;
    }
  }
  .content {
    display: flex;
    background: variables.$secondary;
    flex-direction: column;
    width: 40%;
    justify-content: center;
    margin: 0 auto;
    h1 {
      font-family: "Rubik-Bold";
      padding: 60px 0;
      text-align: center;
      letter-spacing: 2px;
      color: variables.$white;
    }
    form {
      width: 70%;
      margin: 0 auto;
      input {
        margin-bottom: 30px;
        &:disabled {
          opacity: 0.5;
        }
      }
      padding-bottom: 30px;
    }
  }
}

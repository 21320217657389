.map_constraints {
    width: 100% !important;
}

.map_container {
    width: 70%;

    .map_form {
        width: 300px;
        margin-bottom: -157px;
        z-index: 2;
        position: relative;

        .culture_select {
            margin-top: 20px;
            background: white;
            text-align: left;
        }
    }
    .map_element {
        width: 100%;
        height: 90vh;

        // this overrides default buttons for OpenLayers and adds our custom buttons
        button {
            background: rgba(255, 249, 249, 0.7);
            color: unset !important;
            border: 1px solid #d3d3d3;
            padding: 5px;
            margin: 5px;
            float: right;
            width: 50px;
            height: 50px;
            &:hover {
                background: rgba(255, 249, 249, 1);
            }
        }
        .ol-rotate,
        .ol-attribution {
            display: none;
        }
        ul {
            list-style: none !important;
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
    .ol-popup {
        position: absolute;
        background-color: white;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
        padding: 15px;
        border-radius: 10px;
        border: 1px solid #cccccc;
        min-width: 280px;
        top: 0;
        left: 0;
        z-index: 9;
    }
    .ol-popup:after,
    .ol-popup:before {
        top: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    .ol-popup:after {
        border-top-color: white;
        border-width: 10px;
        left: 48px;
        margin-left: -10px;
    }
    .ol-popup:before {
        border-top-color: #cccccc;
        border-width: 11px;
        left: 48px;
        margin-left: -11px;
    }
}
.map_legend {
    width: 170px;
    position: absolute;
    z-index: 2;
    background-color: white;
    text-align: left;
    border: 1px solid #e3e3e3;
    padding: 10px;
    margin-top: -180px;
    margin-left: 20px;
}
.culture_legend {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 5px;
}
.tab_container {
    display: flex;
    gap: 1%;
    margin: 0 80px;
}
.details_container {
    width: 28%;
    margin-right: 5px;
}

.toogleOlView,
.resizeOlView {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        width: 25px;
        height: 25px;
        padding: unset !important;
    }
}

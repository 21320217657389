@use '../../../../assets/styles/variables';

.Result {
  width: 90%;
  margin: auto;
  .modal {
    position: fixed;
    overflow-x: auto;
  }

  .logo {
    margin-right: 40px;
    top: 25px;
    width: 150px;
    height: auto;
    float: right;
  }
  .switchSuggest {
    display: flex;
    flex-direction: row;
    p {
      color: variables.$grey;
      cursor: pointer;
      background: variables.$yellowflat;
      padding: 15px 30px;
      margin-right: 15px;
      margin-bottom: 20px;
      &:hover,
      &.active {
        background: variables.$yellow;
        color: variables.$white;
      }
    }
  }
  .resultContent {
    background: variables.$yellowflat;
    margin-bottom: 50px;

    .simulation-box {
      margin: 0 80px;

      .MuiDataGrid-treeDataGroupingCell span {
        white-space: pre-wrap;
      }

      .classic-row {
        .MuiDataGrid-treeDataGroupingCellToggle {
          margin: 0;

          svg {
            padding-left: 0;
          }
        }
      }

      .previous-campain {
        background-color: #f0f0f0;
      }

      .key-number-campain {
        padding-left: 28px;
      }
      .MuiDataGrid-virtualScroller {
        max-height: unset;
      }
    }

    .datagrid_container {
      overflow-x: auto;
      max-width: 100%;

      .parcel-synthesis-box {
        .arrow-icon {
          color: #277134;
          border-radius: 1000000px;
          background-color: #e69636;
          height: 18px;
          width: 18px;
          margin-right: 10px;
        }
        .MuiDataGrid-cell--editing {
          line-height: 1.4;
          .MuiInputBase-root {
            background: transparent;
          }

          .parcel-synthesis-select-wrapper {
            width: 100%;
            text-align: center;
            position: relative;
            &::before {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              width: 90%;
              height: 95%;
              border: 2px solid variables.$secondary;
              transform: translate(-50%, -50%);
              z-index: 0;
            }

            .wrapper-cover {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: -10px;
              margin-bottom: 5px;
            }

            .parcel-synthesis-select {
              width: 100%;
              font-family: 'Rubik-Medium';
              font-size: 16px;
              color: #707070;
              .MuiSvgIcon-root {
                top: unset;
              }
            }
            & .MuiSvgIcon-root {
              color: #277134;
              border-radius: 1000000px;
              background-color: #e69636;
              height: 18px;
              width: 18px;
              margin-right: 10px;
            }
            .wrapper-cover-icon {
              height: 16px;
              width: 16px;
              display: flex;
              justify-content: center;
              box-shadow: 0px -1px 0px 1px variables.$primary, 0px 1px 0px 1px variables.$primary;
              z-index: 10;
              cursor: pointer;
              .dropletLogo {
                width: 14px;
                height: 14px;
              }
            } 
          }
        }
        & .MuiCell--editing {
          position: relative;
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 52%;
            width: 100%;
            height: 92%;
            border: 2px solid variables.$primary;
            transform: translate(-50%, -50%);
            z-index: 0;
          }
        }
        .MuiDataGrid-cell {
          &.MuiDataGrid-cell--editable {
            cursor: pointer;
          }
          .cell-box {
            display: flex;
            width: 100%;
            min-height: 58px;

            .cell-main-content {
              display: flex;
              flex-direction: column;
              width: 100%;
              align-self: center;
              line-height: 1.4;
              &__infos {
                display: flex;
                justify-content: center;
                align-items: baseline;
                &__surface span {
                  font-style: italic;
                  font-size: 13px;
                  position: absolute;
                  top: 3px;
                  left: 3px;
                }
              }

              > span {
                white-space: pre-wrap;
                text-align: center;
                width: 100%;
              }
            }
            .cultures-icons-wrapper {
              display: flex;
              justify-content: center;
              .culture-infos-icon {
                height: 18px;
                width: 15%;
                display: flex;
                justify-content: center;
                .dropletLogo {
                  height: 14px;
                  width: 14px;
                  color: variables.$secondary;
                }
              }
            }
            & .MuiSvgIcon-root {
              height: 14px;
              width: 14px;
              color: variables.$primary;
              position: absolute;
              top: 3px;
              right: 3px;
            }
          }
        }
      }
    }
    .values {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 50px;
      > div {
        p {
          color: variables.$grey;
          font-size: 18px;
          margin: 0;
        }
        .price {
          font-family: 'Rubik-Bold';
          color: variables.$yellow;
          font-size: 30px;
        }
      }
    }
    .pdfIcon {
      width: 20px;
      height: 20px;
      margin-left: 5px;
    }
    h2 {
      font-size: 25px;
      color: variables.$yellow;
      padding: 10px 30px;
      border-left: 5px solid variables.$yellow;
    }
    .lineChart {
      width: 80%;
      height: 40vh;
      margin: auto;
    }
    .pieChartContainer {
      background: variables.$white;
      display: flex;
      flex-direction: row;
      margin: 40px;
      height: auto;
      .switchYear {
        flex-direction: column;
        width: 15vw;
        padding: 20px;
        height: fit-content;
        p {
          flex: 1;
          font-size: 18px;
          border-radius: 5px;
          display: inline-block;
          color: variables.$grey;
          cursor: pointer;
          background: variables.$yellowflat;
          padding: 5px 15px;
          margin: 5px;
          &:hover,
          &.active {
            background: variables.$yellow;
            color: variables.$white;
          }
        }
      }
      .pieChart {
        width: 50vw;
        height: 40vh;
        @media (max-width: 1250px) {
          width: 35vw;
        }
      }
      .pieChart_table {
        width: 35vw;
        height: 100%;
        padding: 20px;
      }
    }
  }

  .legend_simulation_synthesis {
    font-size: 14px;
    color: variables.$grey;
    font-style: italic;
    margin-bottom: 0;
    margin-left: 50px;
    span {
      font-weight: bold;
      color: variables.$yellow;
    }
    .align-cover {
      display: flex;
      align-items: center;
    }
    .cover-proposal {
      height: 14px;
      width: 14px;
      padding-right: 2px;
    }
  }

  .parcel-synthesis-box {
    .MuiDataGrid-cell {
      white-space: pre-wrap !important;
      text-align: center !important;
      display: flex;
      align-items: center;

      p span {
        font-weight: bold;
        color: variables.$yellow;
      }
    }
  }
}

#custom-tooltip {
  .MuiTooltip-tooltip {
    margin-top: 5px !important;
    background-color: white;
    border: 3px solid variables.$primary;
    box-shadow: 1px 1px 1px grey;
  }
  .tooltip-cover-icon {
    .dropletLogo {
      height: 16px;
      width: 16px;
      padding: 1px 3px;
      cursor: pointer;
    }
  }
}
.MuiDataGrid-colCellSynthesis-parcelle {
    display: flex;
    align-items: center;
    line-height: 1.4 !important;
}

.MuiDataGrid-colCellSynthesis {
  background-color: #f0f0f0;
}

.MuiDataGrid-colCellSynthesis-division {
  flex-direction: column;
  position: relative;
  padding-left: 0 !important;
  & .cell-box-division {
    border-left: black 2px dashed;
    position: relative;
  }
  & .cell-box-division:first-child {
    border-bottom: 1px solid variables.$greylight;
  }
  & .content-with-surface {
    margin-top: 15px;
  }
}

.table-synthesis {
  margin: 0;
  min-width: 100%;
  thead {
    position: sticky;
    top: 0;
    z-index: 2;
    th {
      position: static;
      text-align: center;
      &:first-child {
        padding: 0;
        position: sticky;
        left: 0;
        z-index: 1;
      }
    }
  }
  tbody {
    td {
      &:first-child {
        padding: 0 5px;
        background-color: variables.$white;
        position: sticky;
        left: 0;
        z-index: 1;
        border-right: 1px solid variables.$greylight;
      }
    }
  }
}

.selected-synthesis {
  width: 100%;
  height: 50px;
  padding: 0 !important;
  padding-left: 5px !important;
}

.map_button_container .group_button:last-child {
  margin-left: 15px;
}

.button-synthesis {
  background-color: transparent;
  border: solid 1px variables.$yellow;
  color: variables.$yellow;
  height: 50%;
  margin-left: 100px;

  &:hover {
    background-color: variables.$yellow;
    color: white;
    cursor: pointer;
  }
}

.job_id_display {
  font-size: 12px;
  color: #a9a9a9;
  padding-bottom: 5px;
}

.job_display_margin {
  margin: 20px 0 0px 50px;
}

.cap-points-categories-container {
  &__title {
    display: flex;
    align-items: center;
  }
  &__opened {
    opacity: 1;
    max-height: 1000px;
    overflow: hidden;
    transition: all 0.1s ease-in-out;
  }
  &__closed {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.1s ease-in-out;
  }
}

.cap-points-help-container {
  &__title {
    display: flex;
    align-items: center;
  }
  &__opened {
    opacity: 1;
    max-height: 300px;
    overflow: hidden;
    margin-bottom: 40px;
    transition: all 0.1s ease-in-out;
  }
  &__closed {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.1s ease-in-out;
  }
}

.pdf-page {
  .culture_list_wrapper{
    height: 100%;
    display: flex;
    flex-direction: column;
    h6 {
      margin-top: 0;
      margin-bottom: 2px;
    }
  }

  .table_full_page_culture{
    margin: 2% auto 0 auto !important;
    border-collapse: collapse !important;
    font-size: 12px !important;
    font-family: 'Rubik' !important;
    width: 90% !important;
    height: 100%;
    max-height: 100%;
  }

  .not_name_cell{
    width: calc((1010.25px - 202px) / 8);
  }


  .cell_name {
    width: 202px !important;
    min-width: 202px;

    .name_and_color_wrapper {
      width: 100%;
      align-items: center;
      justify-content: space-between;

      span {
        width: auto !important;
        max-width: calc(100% - 23px);
        white-space: break-spaces;
      }
    }
  }
}

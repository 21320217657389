@use '../../assets/styles/variables';
.backdrop-loading {
  color: '#fff';
  z-index: 2;
  position: 'absolute';
  .loading {
    display: flex;
    gap: 1em;
    align-items: center;
  }
}

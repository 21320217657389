@use "../../../assets/styles/variables";

// #### RESET ####
.page {

  table,
  thead,
  tbody,
  tfoot,
  tr,
  th,
  td {
    //  display: block;

    -webkit-border-horizontal-spacing: 0;
    -webkit-border-vertical-spacing: 0;
    border: none;
    border-collapse: inherit;
    border-color: inherit;
    border-spacing: 0;
    font-weight: inherit;
    height: auto;
    margin: 0;
    padding: 0;
    text-align: left;
    vertical-align: inherit;
    width: auto;
  }
  
  // #### RESET ####
  
  table {
    background: variables.$white;
    width: calc(100% - 160px);
    margin: 0 80px;
    thead {
      color: variables.$white;
      background: variables.$secondary;
      font-family: "Rubik-Bold";
      position: sticky;
      z-index: 1;
  
      tr {
        height: 60px;
        vertical-align: middle;
        th {
          &:first-child {
            border-radius: 5px 0 0 5px;
          }
          &:last-child {
            border-radius: 0 5px 5px 0;
          }
          flex: 1;
          padding-left: clamp(5px, 1.5%, 1.5%);
          padding-right: clamp(5px, 5px, 1.5%);
        }
      }
    }
    tbody {
      font-family: "Rubik-Medium";
      color: variables.$grey;
      tr {
        height: 100px;
        vertical-align: middle;
        box-sizing: border-box;
        td {
          border-bottom: 1px solid variables.$greylight;
  
          padding-left: clamp(5px, 1.5%, 1.5%);
          padding-right: clamp(5px, 5px, 1.5%);
        }
        &:last-child td {
          border-bottom: none;
        }
      }
    }
  }
}
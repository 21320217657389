.actionList {
    position: relative;
    text-align: center;
    > div {
        padding: 10px 0;
        cursor: pointer;
    }
    div.actionContainer {
        font-size: 13px;
        text-align: left;
        box-shadow: 0px 0px 8px 1px #dee2e6;
        position: absolute;
        top: 34px;
        left: calc(50% - 8px);
        padding: 10px 0;
        background-color: #fff;
        border-radius: 5px;
        z-index: 10;
        outline: none;
        div.action {
            cursor: pointer;
            padding: 4px 20px;
            margin: 0;
        }
        div:hover {
            background: #dadada;
        }
    }
}

@use '../../../../assets/styles/variables';

.wrapper-inputs {
    padding-top: 30px;
    max-width: 450px;
    margin: 0 auto;
   & .title_section {
        font-family: 'Rubik-Medium';
        color: variables.$grey;
        font-size: 24px;
        letter-spacing: 0.2rem;
        padding: 20px 0px 20px;
        margin: 0;
      }
    & input {
        width: 95%;
        padding: 10px;
        border: 1px solid variables.$green;
        border-radius: 5px;
        outline: none;
        font-size: 14px;
        cursor: pointer;
    }
    & .wrapper-export {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
    }
    & .wrapper-import {
        display: flex;
        flex-direction: column;
        &__infos {
           display: flex;
           flex-direction: column;
           margin-bottom: 10px;
           color: #707070;
           font-size: 20px;
           &__detail {
            font-weight: bold;
            color: #ffbe25;
            font-size: 20;
           }
        }
        &__button {
            margin-bottom: 10px;
        }
        &__button:last-child button {
           background-color: rgb(231, 76, 60);
           &:hover {
               background-color: variables.$yellow;
           }
        }
        & button {
            align-self: flex-start;
        }
    }
}

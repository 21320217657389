article.pdf-page {
  .table {
    width: 90%;
    margin: 2% 0% 5% 5%;
    border-collapse: collapse;
    font-size: 12px;
    font-family: 'Rubik';

    .head_row {
      padding: 10px;
      font-size: 14px;
      text-align: left;
    }
    .grey_row {
      background-color: #d4d4d4;
    }

    .inline_icon_cell {
      display: flex;
      gap: 10px;
      .cell-icon {
        width: auto;
        height: auto;
        max-width: 30px;
        max-height: 25px;
      }
    }

    .text-left {
      text-align: left !important;
    }

    .cell_flex {
      display: flex;
      flex-direction: column;
      margin: 2px 5px;
    }

    .align_left {
      align-items: start;
    }

    .table_head {
      font-family: 'Rubik';
      font-weight: bold;
      background-color: #134824;
      color: white;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .value_table {
      text-align: center;
      padding: 10px;
      font-size: 14px;
    }

    .column_name {
      text-align: center;
      padding: 10px;
      font-size: 14px;
    }

    .cell_culture_dashed {
      border-left: black 2px dashed;
    }

    .parcel_value {
      font-size: 13px;
      padding: 6px;
      text-align: center;
      height: 61px;

      .cell-content {
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        max-height: 60px;
      }
      .cell-content-name {
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        flex-grow: 1;
        align-content: center;
      }
      & .parcel_value_surface {
        position: absolute;
        top: 0;
        left: 0;
        font-style: italic;
        font-size: 10px;
        background-color: white;
        z-index: 10;
        }
    }

    .column_name_culture {
      text-align: center;
      padding: 10px;
      font-size: 14px;
      height: 79px !important;
    }

    .value_table_culture {
      box-sizing: border-box;
      text-align: center;
      padding: 6px;
      font-size: 14px;
    }

    .column_name_culture,
    .column_name {
      font-weight: bold;
    }

    tr.table_tr {
      align-items: center;
    }

    tbody tr.table_tr {
      border-left: 1.5px solid #134824;
      border-right: 1.5px solid #134824;
      border-bottom: 1.5px solid #134824;
    }

    .goutelette_img {
      width: 10px !important;
    }
  }

  .tableau_center {
    display: flex;
    flex-direction: column;
    h6 {
      margin-top: 0;
      margin-bottom: 2px;
    }
  }

  .table-full-page {
    margin: 2% auto 0 auto !important;
    border-collapse: collapse !important;
    font-size: 12px !important;
    font-family: 'Rubik' !important;
    table-layout: fixed !important;
    width: 90% !important;
    height: 100%;
    max-height: 100%;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .flex-column-synthesis {
    display: flex;
    flex-direction: column;
    min-height: 37px;
    max-height: 61px;
    gap: 4px;
  }

  .flex-column-synthesis-splitted {
    height: 37px;
    position: relative;
  }

  .flew-grow {
    flex-grow: 1;
    min-height: 35px !important;
  }
}

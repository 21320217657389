@use "../../assets/styles/variables";

select,
option {
  font: -moz-pull-down-menu;
  font-size: 16px;
}
select {
  font-family: 'Rubik';
  padding: 2px 20px;
  -moz-padding-start: 5px;
  border-radius: 5px;
  background: variables.$white;
  border: solid 1px variables.$greylight;
  color: variables.$grey;
  &.reduce {
    min-width: 20%;
  }
  &.form {
    margin-bottom: 30px;
    margin-top: 5px;
    width: 100%;
  }
  &.medium_size {
    width: 80%;
    margin-bottom: 30px;
    margin-top: 5px;
  }
}

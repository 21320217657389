.cover-page-pdf {
  .cover {
    margin: auto;
    display: flex;
    flex-direction: column;
    height: 86%;
    justify-content: space-between;

    .job_id_display {
      margin-left: 100px;
    }

    .logo {
      max-width: 621px;
      max-height: 250px;
      margin: auto;
    }

    .title {
      text-align: center;
      margin-top: -50px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      h3 {
        color: #2f3640;
        font-size: 42px;
        margin-bottom: 20px;
        font-weight: normal;
      }

      h1 {
        font-size: 56px;
        margin-bottom: 20px;
        border-left: 20px solid #e69636;
        text-transform: uppercase;
        color: #2f3640;
        margin: 0px 200px;
        padding: 20px;
        font-weight: bold;
        line-height: 1;
      }
    }

    .info_cover {
      display: flex;
      flex-direction: row;
      margin: 0px 100px;
      margin-top: 50px;
      height: 120px;
      .info {
        background-color: #134824;
        border-left: 2px solid #e69636;
        height: 100%;
        width: 33.33%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 24px;

        .info_value {
          margin-top: 10px;
          text-align: center;
        }
      }
    }
  }
}

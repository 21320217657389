:root {
  //this 2 first variables below are initiated here but the final used values is calculated in PdfComponent file
  --height-pdf: calc(21cm - 70px);
  --margin-bottom-pdf: 70px;

  --margin-top-pdf: 30px;
  --pdf-h6-height: 30px;
  --pdf-h6-margin-bottom: 5px;
}

article.pdf-page {
  //due to the merging of the margin bottom of a page with the margin top of the following
  //page we have to add margin top and margin bottom so that margin top is taken into account
  margin-top: calc(var(--margin-bottom-pdf) + var(--margin-top-pdf));
  font-family: 'Rubik';
  //height of the page content is 21cm minus margin top and margin bottom
  height: calc(var(--height-pdf) - var(--margin-top-pdf));
  width: 29.7cm;
  background: #fff;
  margin-bottom: var(--margin-bottom-pdf);

  .title_h6 {
    display: block;
    border-bottom: 2px solid black;
    margin: auto;
    color: black;
    font-size: 25px;
    text-align: center;
    text-transform: uppercase;
    word-spacing: 6px;
  }
}

//we have to seperate css rules of first page of pdf document because they are not the same
.cover-page-pdf {
  margin-top: var(--margin-top-pdf);
  font-family: 'Rubik';
  height: calc(var(--height-pdf) - var(--margin-top-pdf));
  width: 29.7cm;
  background: #fff;
  margin-bottom: var(--margin-bottom-pdf);
  .low_separator {
    height: 10%;
    width: 100%;
  }
}

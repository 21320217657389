@use "../../assets/styles/variables";

button {
  padding: 20px 40px;
  color: variables.$white;
  background-color: variables.$primary;
  border: none;
  border-radius: 5px;
  line-height: 20px;
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  &.secondary {
    background-color: variables.$secondary;
  }
  &.form {
    background-color: variables.$white;
    color: variables.$green;
    font-size: 14px;
    letter-spacing: 0.1rem;
  }
  &.spacing {
    margin: 0 20px;
  }
  &.spacingV {
    margin-bottom: 10px;
  }
  svg {
    padding-left: 10px;
  }
  &.delete_it {
    padding: 5px !important;
    background-color: variables.$white;
    color: variables.$secondary;
    margin-bottom: 6px;
  }
}

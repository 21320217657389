@use '../../../../assets/styles/variables';

.extraModalContainer {
  border: 1px solid white;
  margin: 60px auto 60px auto;
  width: 450px;
  background: white;
  min-height: 1;
  position: relative;
}
.extraModalTittle {
  padding: 22px 10px;
  height: fit-content;
  background: variables.$green;
}
.extraModalTittleText {
  font-size: 20px;
  color: white;
  font-family: Rubik-Medium;
}
.extraModalBody {
  margin: 30px auto 20px auto;
  padding: 0px 40px;
  max-height: calc(90vh - 240px);
  overflow-y: scroll;
}

#mui-component-select-synthParcelle {
  display: flex;
  align-items: center;
}

.section {
  & .title_section_culture {
    color: variables.$green;
    display: inline-block;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      width: 120px;
      height: 5px;
      background: variables.$yellow;
      top: 53px;
      bottom: 0;
      right: 0;
      left: 107px;
    }
  }
  & .contain_header_icon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    max-width: 95%;
    & svg {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
    & .mui_icon_header {
      padding: 0 10px;
      display: flex;
      align-items: center;
      &:hover {
        color: variables.$secondary;
      }
    }
  }
  .datagrid_container {
    overflow-x: auto;
    max-width: 100%;
  }
}
 
.modal_header {
  & .close-icon {
    position: absolute;
    right: 20px;
  }
}

#add_exploitation_dialog {
  & .MuiDialog-paper {
    width: 1000px;
    height: 47vh;
  .add_exploitation_container {
    overflow-y: auto;
    &__box {
      margin-bottom: 25px;
    }
    & input {
      &::placeholder {
        font-size: 14px;
      }
    }
    & .label_asterisk {
      color: variables.$yellow;
      font-size: 18px;
    }
    & #add_exploitation_button {
      background-color: variables.$yellow;
      display: unset;
      &:hover {
        background-color: variables.$green;
      }
    }
    & #add_farmer_button {
      display: unset;
      margin-left: 10px;
      &:disabled {
        background-color: rgb(99, 119, 99);
        cursor: not-allowed !important;
        pointer-events: unset !important;
      }
    }
    .add_exploitation_fieldset, .add_farmer_fieldset {
      position: relative;
      border: 1px solid variables.$green;
      text-align: start;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      & legend {
        margin-left: 20px;
        color: variables.$green;
        font-family: Rubik-Bold !important;
      }
    }
    .text_helper_exploitation {
      width: 100%;
      display: flex;
      flex-direction: row;
      font-style: italic;
      font-size: 14px;
      justify-content: center;
      color: variables.$green;
      opacity: 0.8;
      gap: 5px;
      margin-bottom: 15px;
    }
    
    #custom-delete-button {
        background-color: red;
        &:hover {
          background-color: variables.$primary;
        }
      }
    .add-farmer-list {
        display: flex;
        flex-direction: column;
        min-width: 90%;
  
        & .MuiButtonBase-root {
          all: unset;
          display: flex;
          font-size: 16px !important;
          align-items: center;
          padding: 15px;
          border-radius: 20px;
          margin: 5px;
          border: 1px solid variables.$primary;
          cursor: pointer;
  
          &:hover {
            opacity: 0.75 !important;
            background-color: none !important;
          }
  
          & .MuiSvgIcon-root {
            font-size: 22px !important;
          }
        }
      }
  }
  }
}
  
.pdf-page {
  .multiple-graph-bloc {
    display: flex;
    flex-direction: column;
    height: 675px;
    .cultures-map-tab-container table {
      width: auto;
    }
    .table-production {
      height: auto;
    }
  }
  --margin-top-graph: 100px;
  .graph {
    width: 100%;
    height: 580px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .multiple-graph-bloc .title_campagne {
    margin-top: 0px;
    font-size: 15px;
    color: black;
    text-align: center;
    text-transform: capitalize;
    word-spacing: 4px;
  }

  .graph-production {
    margin: auto;
    height: auto;
    width: auto;
    max-height: 312px;
    max-width: 100%;
    display: block;
  }

}

.pdf-page {
  .color {
    width: 13px;
    height: 13px;
    border-radius: 100px;
  }

  .icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5px;
  }
  .legend_simulation_synthesis {
    position: absolute;
    top : 780px;
    width: 280px;
    font-size: 10px;
    font-style: italic;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    .legend-cover {
      display: flex;
      gap: 10px;
      align-items: center;
      img {
        width: 10px !important;
      }
    }
  }
}
$yellow: #e69636;
$yellowflat: #fffbf1;
$green: #277134;
$white: #ffffff;
$whitelight: #ffffff7a;
$black: #000;
$grey: #707070;
$greylight: #ebebeb;
$red: #d12d38;

$primary: $yellow;
$secondary: $green;

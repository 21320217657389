@use "../../../../assets/styles/variables";

.input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.input-file+label {
  padding: 20px 40px;
  color: #ffffff;
  background-color: variables.$yellow;
  border: none;
  border-radius: 5px;
  line-height: 20px;
  cursor: pointer;
}
#custom-add-dialog .MuiPaper-root {
  width: 1000px;
  height: 55vh;

  .modal-container {
    .add-header-container {
      display: flex;
      padding: 0 20px 0 20px;
      width: 100%;
    }

    .add-admin-list {
      display: flex;
      flex-direction: column;
      min-width: 90%;

      & .MuiButtonBase-root {
        all: unset;
        display: flex;
        align-items: center;
        padding: 15px;
        border-radius: 20px;
        margin: 5px;
        border: 1px solid variables.$primary;
        cursor: pointer;

        &:hover {
          opacity: 0.75;
        }

        & .MuiSvgIcon-root {
          font-size: 22px !important;
        }
      }
    }

    & #add-button-admin {
      & #MuiSvgAddIcon {
        color: unset !important;
      }
    }

    .add-logo-structure {
      width: 100% !important;
      display: flex;

      & .MuiFormLabel-root {
        margin-top: 20px;
      }

      & .MuiBox-root:first-child {
        margin: -30px 10px -10px 0;
        min-width: 125px !important;
        max-width: 150px;
      }

      & .MuiButtonBase-root {
        width: 130px;
      }

      & .container-logo {
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 100%;
      }

      & .MuiAvatar-root {
        width: 52px;
        height: 52px;
      }

      img {
        max-width: 100%;
        max-height: 100px;
      }
    }

    & .inputLabel-admin {
      display: inline-flex;
      color: variables.$secondary;
      align-items: center;

      & .MuiButton-root {
        display: flex;
      }
    }

    & .add_culture_fieldset {
      margin-top: 40px;

      & .label-input-file {
        display: inline-flex;
      }
    }
  }

  & .button-submit-structure {
    display: flex;
    width: 55% !important;
    & #custom-delete-button {
      background-color: red;
      &:hover {
        background-color: variables.$primary;
      }
    }
  }
}
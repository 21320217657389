@use "../../assets/styles/variables";

.lds-container {
  margin-top: 50px;
  text-align: center;
  vertical-align: middle;
  .lds-default {
    display: inline-block;
    position: relative;
    margin-right: auto;
    width: 80px;
    height: 80px;
    div {
      position: absolute;
      width: 6px;
      height: 6px;
      background: variables.$green;
      border-radius: 50%;
      animation: lds-default 1.2s linear infinite;
      &:nth-child(1) {
        animation-delay: 0s;
        top: 37px;
        left: 66px;
      }
      &:nth-child(2) {
        animation-delay: -0.1s;
        top: 22px;
        left: 62px;
      }
      &:nth-child(3) {
        animation-delay: -0.2s;
        top: 11px;
        left: 52px;
      }
      &:nth-child(4) {
        animation-delay: -0.3s;
        top: 7px;
        left: 37px;
      }
      &:nth-child(5) {
        animation-delay: -0.4s;
        top: 11px;
        left: 22px;
      }
      &:nth-child(6) {
        animation-delay: -0.5s;
        top: 22px;
        left: 11px;
      }
      &:nth-child(7) {
        animation-delay: -0.6s;
        top: 37px;
        left: 7px;
      }
      &:nth-child(8) {
        animation-delay: -0.7s;
        top: 52px;
        left: 11px;
      }
      &:nth-child(9) {
        animation-delay: -0.8s;
        top: 62px;
        left: 22px;
      }
      &:nth-child(10) {
        animation-delay: -0.9s;
        top: 66px;
        left: 37px;
      }
      &:nth-child(11) {
        animation-delay: -1s;
        top: 62px;
        left: 52px;
      }
      &:nth-child(12) {
        animation-delay: -1.1s;
        top: 52px;
        left: 62px;
      }
    }
  }

  @keyframes lds-default {
    0%,
    20%,
    80%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }
}

.fullscreen-loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: rgba(0,0,0, 0.5);
  z-index: 999;
  &.full{
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
  }
  .center-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .spinner {
    margin-bottom: 15px;
    width: 64px;
    height: 64px;
    border: 8px solid variables.$greylight;
    border-top: 8px solid variables.$green;
    border-radius: 50%;

    animation: spin 2s linear infinite;
  }
  .text {
    font-weight: bold;
    color: whitesmoke;
    text-shadow: 0.1em 0.1em 0.2em black;
    font-size: 20px;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .close {
    position: absolute;
    display: inline-block;
    top: 20px;
    right: 20px;
    svg {
      cursor: pointer;
      padding: 5px;
    }
  }
}


@use "../../assets/styles/variables";

input {
  border: none;
  border-bottom: 1px solid;
  border-color: variables.$black;
  padding: 5px 0;
  outline-width: 0;
  width: 100%;
  font-family: "Rubik";
  &::-webkit-input-placeholder,
  &::-moz-placeholder {
    color: variables.$grey;
  }
  &.primary {
    border-color: variables.$yellow;
    &::-webkit-input-placeholder {
      color: variables.$yellowflat;
    }
    &::-moz-placeholder {
      color: variables.$yellowflat;
    }
  }
  &.secondary {
    border-color: variables.$white;
    background-color: variables.$green;
    color: white;
    &::-webkit-input-placeholder {
      color: variables.$whitelight;
    }
    &::-moz-placeholder {
      color: variables.$whitelight;
    }
  }
  &.login {
    font-family: "Rubik";
    border-color: variables.$grey;
    color: variables.$grey;
    padding-top: 15px;
    margin-bottom: 50px;
    &::-webkit-input-placeholder {
      color: variables.$grey;
    }
    &::-moz-placeholder {
      color: variables.$grey;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

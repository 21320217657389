.pdf-page {
  .title_key {
    margin-top: -5px;
  }

  tr.key_table_tr {
    align-items: center;
    height: 10px;
  }
  tbody tr.key_table_tr {
    border-left: 1.5px solid #134824;
    border-right: 1.5px solid #134824;
    border-bottom: 1.5px solid #134824;
  }

  .marge_brute_graph {
    display: flex;
    flex-direction: column;
    height: 100%;

    .title_h6 {
      margin-top: 0px;
      margin-bottom: 2px;
    }
  }

  .marge_brute_graph img {
    display: block;
    width: auto;
    height: auto;
    max-width: 90%;
    max-height: 540px;
    margin: auto 5%;
  }
}

@use "../../../assets/styles/variables";

.modal_delete {
  background: rgba(0,0,0, 0.5);
  position: fixed;
  width: 100vw;
  min-height: 100%;
  top: 0;
  left: 0;
  z-index: 201;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    display: flex;
    background: variables.$white;
    flex-direction: column;
    width: fit-content;
    padding: 20px;
    border-radius: 10px;
    align-items: center;
    margin: 0 auto;
    button {
      margin: 5px;
      padding: 10px 20px;
    }
  }
}
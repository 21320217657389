@use "../../assets/styles/variables";

.nav {
  display: flex;
  position: sticky;
  top: 75px;
  width: 100%;
  background-color: variables.$primary;
  justify-content: center;
  overflow-y: clip;
  transition: all 0.5s ease-out;
  z-index: 5;
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.2);
  .nav-contains {
    display: flex;
    justify-content: space-evenly;
    min-width: 60%;
    align-items: center;
    width: 90%;
  }
  a {
    cursor: pointer;
    color: variables.$white;
    text-decoration: none;
    &.active,
    &:hover {
      font-family: "Rubik-SemiBold";
    }
    > div {
      display: block;
      padding: 29px 10px;
      font-family: "Rubik-Medium";
      font-size: 16px;
      letter-spacing: 2.5px;
      &.active,
      &:hover {
        background-color: variables.$white;
        color: variables.$primary;
        font-family: "Rubik-SemiBold";
        border-radius: 0;
      }
    }
  }
}
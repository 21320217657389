@use 'reset';
@use 'breakpoints';
@use 'responsive';
@use 'typography';
@use 'variables.scss';

html {
  font-family: 'Rubik';
  .page {
    position: relative;
    flex-direction: row;
    .contain { 
      transition: 0.5s;
    }
  }
}

.text-center {
  text-align: center;
}

.d-block {
  display: block;
}

.m-auto {
  margin: auto;
}

.error {
  color: variables.$red;
}

.errorForm {
  background-color: #eb4d4b;
  min-width: 70% !important;
  color: white;
  padding: 10px;
  border-radius: 5px;
}

.title {
  color: variables.$grey;
  font-size: 30px;
  letter-spacing: 3px;
}

.green_text {
  font-family: 'Rubik-Bold';
  color: variables.$secondary;
  letter-spacing: 3px;
  font-size: 18px;
  margin: 0;
}
.title_section_recapitulatif .green_text{
  font-size: inherit;
}

.exploitationHeaderSticky{
  font-size:0.6em ;
  font-family: 'Rubik';
  color:black ;
}

.title_spacing {
  padding-left: 80px;
  display: inline-block;
}

.subtitle_spacing {
  padding-left: 10px;
  vertical-align: middle;
  display: inline-block;
}
.searchbar_spacing {
  margin-left: 80px;
}

.div_searchbar_spacing {
  padding-right: 80px;
}

.title_searchbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.searchbar_align {
  text-align: end;
}

.contain-header-seachbar {
  .add_button_spacing {
    padding: 20px 50px !important;
  }
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
}

.title_no_padding_top {
  padding-top: 0 !important;
}

.text_white {
  color: variables.$white;
}

.full-height {
  height: calc(100vh - 120px) !important;
}

.flex {
  display: flex;
}

.align-item-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 80px 40px 80px;
  @media (max-width: 1600px) {
    padding: 0 80px 20px 80px;
  }
  .override & {
    padding: 0 80px !important;
  }
}

.flex-row-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

fieldset {
  padding-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  &.co_products {
    section {
      align-items: center;
    }
    div {
      display: flex;
      align-items: center;
    }
  }
}

.flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.align-self-center {
  align-self: center;
}

.justify-space-between {
  justify-content: space-between;
}

.red {
  color: variables.$red;
}
.black {
  color: variables.$black;
}
.green {
  color: variables.$green;
}
.orange {
  color: variables.$yellow;
}

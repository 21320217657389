@use '../../../assets/styles/variables';

.extraModalContainer {
  border: 1px solid white;
  margin: 60px auto 60px auto;
  width: 450px;
  background: white;
  min-height: 1;
  position: relative;
}
.extraModalTittle {
  padding: 22px 10px;
  height: fit-content;
  background: variables.$green;
}
.extraModalTittleText {
  font-size: 20px;
  color: white;
  font-family: Rubik-Medium;
}
.extraModalBody {
  margin: 30px auto 20px auto;
  padding: 0px 40px;
  max-height: calc(90vh - 240px);
  overflow-y: scroll;
}

.cultureColorCircle {
  margin-left: 10px;
  min-width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #707070;
}

.colorContainer {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.colorBlockContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.colorBlock {
  padding: 5px;
  border-radius: 1px;
  display: inline-block;
  cursor: pointer;
  margin-top: 0.5rem;
  &__modal {
    padding: 0 5px 0 0;
  }
}

.colorStyle {
  width: 70px;
  height: 30px;
  border-radius: 5px;
  border: 2px solid variables.$yellow;
}

.colorPicker {
  position: absolute;
  bottom: 0;
  z-index: 2;
}

.color_label {
  width: 100% !important;
}

.colorPickerCover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.colorPickerPosition__modal {
  position: relative;
  top: 250px;
  right: 0px;
  bottom: 0px;
  left: 20px;
  overflow: hidden;
}

.colorPickerPosition {
  position: relative;
  top: -50px;
  right: 0px;
  bottom: 0px;
  left: 50px;
  @media (max-width: 1900px) {
    left: 65px;
  }
  @media (max-width: 1750px) {
    left: 75px;
  }
  @media (max-width: 1750px) {
    left: 85px;
  }
  @media (max-width: 1550px) {
    left: 95px;
  }
}

.blockCultureCircle {
  display: flex;
  align-items: center;
}

#mui-component-select-synthParcelle {
  display: flex;
  align-items: center;
}

.meta-culture-name div div span{
  display: -webkit-box;
  white-space: pre-wrap;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.section {
  & .title_section_culture {
    color: variables.$green;
    display: inline-block;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      width: 120px;
      height: 5px;
      background: variables.$yellow;
      top: 53px;
      bottom: 0;
      right: 0;
      left: 107px;
    }
  }
  & .contain_header_icon {
    display: flex;
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
    & svg {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
    & .MuiBox-root {
      display: flex;
      align-items: center;
      @media (max-width: 1550px) {
        flex-wrap: wrap;
        width: 50%;
      }
    }
    & .mui_icon_header {
      padding: 0 5px;
      white-space: nowrap;
      margin-bottom: 5px;
      &:hover {
        color: variables.$secondary;
      }
    }
    .nitrogen_container {
      display: flex;
      justify-content: flex-start;
      @media (max-width: 1550px) {
        justify-content: center;
        width: 50%;
      }
      @media (max-width: 1275px) {
        flex-wrap: wrap;
      }
      .MuiBox-root {
        margin: 5px;
        background-color: #277134;
        border-radius: 4px;
        padding: 0px 15px;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
        height: 50px;
        display: flex;
        align-items: center;
        width: 250px;
      }
    }
    .buttons_container {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-end;
      padding-top: 5px;
    }
  }
  .datagrid_container {
    overflow-x: auto;
    max-width: 100%;
  }
}
#off_crop_settings_dialog {
    .MuiFormLabel-root {
      width: 190px !important;
    }
    .title-off-crop-settings {
      margin: 0;
      padding-bottom: 10px;
      width: 100%;
      color: variables.$secondary;
    }
    .wrapper-box-off-crop-settings {
      margin-bottom: 0;
    }
}

.cellColorMuiContainer {
  width: unset;
  position: absolute;
  display: block;
  & .add-color-mui {
    height: 35px;
    text-align: center;
    padding: 10px 20px;
    font-size: 15px;
    user-select: none;
    display: flex;
    align-items: center;
  }
  & .colorStyleMui {
    width: 30px;
    height: 20px;
    border-radius: 5px;
    border: 2px solid variables.$yellow;
  }
  & .colorPickerPositionMui {
    top: 430px;
    left: 0;
  }
}

.Cooperative .modal {
  height: auto;
}

.culture-center-cell {
  white-space: pre-wrap !important;
  text-align: center;
}

.modal_header {
  & .close-icon {
    position: absolute;
    right: 20px;
  }
}

.add_culture_container {
  overflow-y: auto;
  & input {
    &::placeholder {
      font-size: 14px;
    }
  }
  & .label_asterisk {
    color: variables.$yellow;
    font-size: 18px;
  }
  & #add_name {
    margin-left: 20px;
    min-width: 500px !important;
  }
  & #add_culture_button {
    background-color: variables.$yellow;
    display: unset;
    &:hover {
      background-color: variables.$green;
    }
  }
  & #add_coProduct_button {
    display: unset;
    margin-left: 10px;
    &:disabled {
      background-color: rgb(99, 119, 99);
      cursor: not-allowed !important;
      pointer-events: unset !important;
    }
  }
  & .add_culture_fieldset {
    position: relative;
    border: 1px solid variables.$green;
    text-align: start;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    & legend {
      margin-left: 20px;
      color: variables.$green;
      font-family: Rubik-Bold !important;
    }
  }
  & .add_culture_select {
    display: flex;
    flex-wrap: wrap;
    & .irrigationWaterNeededBox {
      margin-left: 22px !important;
    }
  }
  & .add_culture_checkbox {
    display: flex;
    align-items: flex-end;
    & .MuiBox-root {
      min-width: 150px !important;
    }
    & label {
      white-space: nowrap !important;
      min-width: 0 !important;
    }
  }
  & #add_culture_coProducts_container {
    width: 100% !important;
    & .add_culture_coProducts_section {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100% !important;
      margin-bottom: 5px !important;
      & #coproduct_box {
        width: 40% !important;
        & .coproduct_delete {
          margin-left: 25px;
        }
      }
    }
  }
  .text_helper_culture {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-style: italic;
    font-size: 14px;
    color: variables.$green;
    opacity: 0.8;
    gap: 5px;
    margin-bottom: 15px;
  }
  & .colorBlockContainer {
    & .color_label {
      text-align: left !important;
      max-width: unset !important;
      padding-left: 10px;
    }
    & .colorBlock__modal {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }
  }
}

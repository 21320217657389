@use "../../assets/styles/variables";

.mainNav {
  background-color: variables.$secondary;
  height: 75px;
  position: sticky;
  top: 0;
  flex-direction: row;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #707070;
  overflow-y: clip;
  z-index: 6;
  .mainNav-contains {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .ribbon{
      cursor: pointer;
      width:10%;
      height:100%;
      position:absolute;
      left:0;
    }
  }
  .items-contains {
    display: flex;
    min-width:50%;
    align-items: center;
  }
  .bar-close {
    padding: 45px 20px 32px 0px;
    cursor: pointer;
    & > svg {
      width: 35px;
    }
  }
  .nav-logo {
    cursor: pointer;
    margin: 3px 0px 0px 3px;
    width: 300px;
  }
  .bar-box {
    z-index: 0;
    display: flex;
    align-items: center;
  }
  a {
    text-decoration: none;
    .item {
      height: 51px;
      display: flex;
      padding: 25px 25px;
      align-items: center;
      color: #FFF;
      font-family: "Rubik-Medium";
      font-size: 18px;
      &.active {
        color: variables.$primary;
        background-color: variables.$white;
        font-family: "Rubik-SemiBold";
      }
      svg {
        padding-right: 10px;
      }
    }
  }
  .logout-icon {
    width: 35px;;
    height: 35px;
    cursor: pointer;
  }
}

.ribbon-close{
  margin-left: 4.5px;
}

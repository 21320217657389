@use "../../../assets/styles/variables";

.Login {
  display: flex;
  flex-direction: row;
  height: 100vh;
  > div {
    &.form {
      display: flex;
      flex: 4;
      flex-direction: column;
      justify-content: space-around;
      .container {
        margin: 0 auto;
        width: 40%;
        button {
          margin: 30px auto;
        }
        .title {
          margin: 80px 0;
        }
      }
      .recenterLogo {
        width: 303px;
        .resizeLogo {
          width: 100%;
        }
      }
      a {
        color: variables.$green;
        text-decoration: none;
        font-family: "Rubik-Medium";
      }
    }
    &.img {
      display: flex;
      flex: 2;
      justify-content: flex-end;
      background-image: url("../../../assets/images/loginImg.png");
      background-repeat: no-repeat;
      background-position: top left;
    }
  }
}

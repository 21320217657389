.telepacImportContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
  
    & .importDetailsTitle {
      color: #707070;
      font-size: 20px;
    }
    & .buttonContainer {
      padding: 40px;
    }
    & .importDetailsDescription {
      font-weight: bold;
      color: #ffbe25;
      font-size: 20px;
    }
    & .buttonUpload {
      display: flex;
      flex-direction: row;
      margin-top: 50px;
    }
    & .importDetailsSelectGroundType{
      margin-top: 20px;
      label {
        font-size: 21px;
        font-weight: bold;
        margin-bottom: 15px;
      }

    }
  }
  @media(min-width: 1200px) {
    .telepacImportContainer {
      height: unset;
    }
  }


  
@use "../../assets/styles/variables";

.checkbox_with_label {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0;
  cursor: pointer;
  .checkbox_container {
    cursor: pointer;
    width: 20px;
    height: 20px;
    border: 1px solid variables.$primary;
    .checkbox_checked {
      width: 70%;
      height: 70%;
      background: variables.$primary;
      margin-left: 15%;
      margin-top: 15%;
    }
  }
  p {
    font-size: 15px;
    color: variables.$grey;
    padding-left: 20px;
    margin: 0;
    .modal & {
      color: variables.$white;
    }
  }
  &.children {
    padding-left: 30px;
  }
}

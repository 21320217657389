@use "../../assets/styles/variables";

label {
  &.secondary {
    color: variables.$white;
    font-family: "Rubik-SemiBold";
  }
  &.login {
    color: variables.$grey;
    font-family: "Rubik-SemiBold";
  }
  &.width-middle {
    width: 48%;
  }
}

@use '../../../assets/styles/variables';

.parcel_datagrid_container {
  & .parcel_children {
    justify-content: end !important;
    position: relative;
    margin-left: -10px !important;
    margin-right: 10px;
  }

  & .first-children {
    justify-content: start !important;
    // position: absolute;
    padding-left: 8% !important;
  }

  & .first-children::before {
    content: '';
    border-bottom: 1px solid #000;
    border-left: 1px solid #000;
    bottom: 0;
    height: 50%;
    left: -5px;
    position: relative;
    top: -15px;
    width: 10px;
  }
  .MuiDataGrid-cell {
    white-space: pre-wrap !important;
    text-align: center !important;
  }
}

.menu-item {
  border: none !important;
  &__border {
    border-bottom: 1px solid variables.$yellow !important;
  }
}

.close-icon {
  position: absolute;
  right: 20px;
}
.checkbox-irrigable {
  position: absolute !important;
  right: 100px !important;
}

.text-info-parcel {
  margin-top: -15px;
  font-size: 14px;
}

.css-123p03q-MuiPopper-root-MuiTooltip-popper {
  z-index: 1300 !important;
}

@use "../../assets/styles/variables";

.button-top-simulate {
    color: #fff !important;
    background-color: variables.$primary !important;
    filter: brightness(0.95);
    border-radius: 4px !important;
    padding: 12px 22px !important;
    font-size: 16px  !important;
    font-family: Rubik-Medium !important;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    text-transform: capitalize !important;
    letter-spacing: 0.5px !important;
    height: 50px;
    transition: 0.1s !important;

    &:hover {
        filter: brightness(1);
    }
}
@font-face {
  font-family: "Rubik-Light";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/Rubik-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Rubik-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Rubik-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik-SemiBold";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/Rubik-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Rubik-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik-ExtraBold";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/Rubik-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik-Black";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/Rubik-Black.ttf") format("truetype");
}

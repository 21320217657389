.pdf-page {
  .row_label {
    width: 280px;
  }

  .nitrogen_culture {
    margin-left: 50px;
    height: inherit;

    img {
      width: 22px !important;
      height: 25px !important;
    }

    span {
      height: inherit;
      overflow-y: hidden;
      align-content: center;
      flex-grow: 1;
      text-align: left;
    }
  }

  .nitrogen_tr {
    height: 51px !important;

    .nitrogen_td {
      height: 31px;
    }
  }
}

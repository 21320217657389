@use '../../../../assets/styles/variables';

.result-main {
  margin: auto;
  max-width: 90vw;
  & .contain-title {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-right: 10px;
    position: sticky;
    transition: top 0.5s ease-out;
    z-index: 20;
    background: variables.$white;
    & .title_section_forecast {
      width: 100%;
      padding: 20px 0 40px;
    }
    & button {
      text-transform: uppercase;
      background: #277134;
      font-family: 'Rubik-Medium';
      padding: 12px 22px;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
      transition: 0.1s;
      letter-spacing: 0.8px;
      &:hover {
        background: variables.$primary;
      }
    }
  }
  & .title_section_result {
    color: variables.$secondary;
    display: inline-block;
    position: relative;
  }
  & .container-result {
    border: 1px solid variables.$primary;
    padding: 20px;
    margin: 10px;
    & .result-tableDirection {
      width: 100%;
      justify-content: space-between;
      flex-direction: row;
      display: flex;
    }
    & .table-columnLeft {
      width: 60%;
    }
    & .graph-columnRight {
      width: 40%;
    }
    & .lineChart {
      width: 80%;
      height: 300px;
    }
    & .block-graph {
      margin: 10px;
    }
    & .block-CAPPoints {
      margin-top: -80px;
    }
  }
  & .header-container {
    border: 1.5px solid variables.$secondary;
    border-top-right-radius: 10px 10px;
    border-top-left-radius: 10px 10px;
    & .header {
      padding: 15px 0 15px 15px;
      border-top-right-radius: 10px 10px;
      border-top-left-radius: 10px 10px;
      border-bottom: 1.5px solid variables.$secondary;
      background-color: variables.$primary;
      color: white;
      & span {
        font-weight: bold;
        font-size: 20px;
      }
    }
    & .legend-asterisk {
      color: variables.$grey;
      font-size: 12px;
      font-style: italic;
      margin: 5px;
    }
    & .pie-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      & .custom-pie-production {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 220px;
        justify-content: center;
        align-items: center;
        & .graph {
          height: 200px;
          width: 50%;
          & .graph-title {
            text-align: center;
            color: variables.$green;
            text-decoration: underline;
            font-weight: bold;
          }
        }
      }
      & .legend-container {
        color: variables.$grey;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        font-size: 13px;
        justify-content: flex-start;
        height: 95px;
        margin: 10px 10px 0 10px;
      }
    }
  }
  & .button-calculate {
    margin: 0 30px 0 30px;
    text-transform: uppercase;
    background: variables.$secondary;
    font-family: 'Rubik-Medium';
    padding: 12px 22px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    transition: 0.1s;
    letter-spacing: 0.8px;
    &:hover {
      background-color: variables.$primary;
      color: variables.$white;
      cursor: pointer;
    }
    &:disabled {
      border: 1px solid variables.$secondary;
      background-color: #dbdbdb;
      color: variables.$secondary;
      cursor: not-allowed;
      opacity: 0.6;
    }
  }

  .legend_comparator_result_synthesis {
    font-size: 14px;
    color: variables.$grey;
    font-style: italic;
    margin-bottom: 0;
    span {
      font-weight: bold;
      color: variables.$yellow;
    }
  }
}

.legend-item {
  display: flex;
  align-items: center;
  width: 200px;
  margin-bottom: 2px;
  & .legend-color {
    height: 13px;
    width: 13px;
    margin-right: 5px;
  }
  & .legend-text {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

.pie-tooltip {
  background-color: variables.$white;
  padding: 3px;
  width: auto;
  box-shadow: 4px 4px 0px -2px rgba(106, 106, 106, 0.38);
  & span {
    font-weight: bold;
  }
}

.header-assolia {
  background: variables.$secondary !important;
  max-width: unset !important;
}
.MuiDataGrid-row.Mui-even {
  background-color: variables.$white !important;
  &:hover {
    background-color: variables.$white !important;
  }
}
.MuiDataGrid-row.Mui-odd {
  background-color: #f0f0f0 !important;
  &:hover {
    background-color: #f0f0f0 !important;
  }
}
.block-parcelSynthesis {
  .MuiDataGrid-cell {
    white-space: pre-wrap !important;
    text-align: center !important;

    p span {
      font-weight: bold;
      color: variables.$yellow;
    }
  }
}

.technician-container {
  max-height: 100vh;
  & .main_container {
    max-height: unset;
  }
  & .contain-header {
    width: 95%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    & .formControl {
      width: unset !important;
    }
    & .add_button_container {
      padding: unset;
    }
  }
  & .add-sector-technician-container {
    width: 95%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 20px;
  }
}
